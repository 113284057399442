import { Checkbox, Label } from '@rebass/forms'
import { useTheme } from 'emotion-theming'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { isMobileOnly, isTablet } from 'react-device-detect'
import { Trans, useTranslation } from 'react-i18next'
import { Box, Button, Flex, Text } from 'rebass'
import { usePortraitScreen, useSupportedBrowser } from 'uneeq-react-core'

import { LanguageOption } from '../../customPlugins/LanguageSelector/LanguageOption'
import LanguageSelector from '../../customPlugins/LanguageSelector/LanguageSelector'
import MayaCloseButton from '../../customPlugins/MayaCloseButton/MayaCloseButton'
import { MayaPrivacyPolicy } from '../../customPlugins/MayaPrivacyPolicy'
import { MayaRequestAppointmentButton } from '../../customPlugins/MayaRequestAppointmentButton'
import { MayaTermsOfConditions } from '../../customPlugins/MayaTermsAndConditions'
import MayaUnsupportedBrowser from '../../customPlugins/MayaUnsupportedBrowser'
import { SingleUserData, UserData } from '../../hooks/useApplicationState'
import { fnOnEnter } from '../../utils'
import { ReactComponent as BackIcon } from '../assets/icons/back.svg'
import { ReactComponent as NotesSvg } from '../assets/icons/notes.svg'
import landingImg from '../assets/landing/LandingPageVideo.jpg'
import landingVideo from '../assets/landing/LandingPageVideo.mp4'
import portaitImg from '../assets/landing/LandingPageVideoPortrait.jpg'
import portaitVideo from '../assets/landing/LandingPageVideoPortrait.mp4'
import { useTvContext } from '../hooks/useTvContext'
import { PatientSelector } from './PatientSelector'

import styles from './styles'

interface SophieVideoProps {
  desktopOnly: boolean
  widgetMode: boolean
  renderLanguageSelector: () => JSX.Element
  isSmallScreen: boolean
}

const SophieVideo = ({
  desktopOnly,
  widgetMode,
  renderLanguageSelector,
  isSmallScreen
}: SophieVideoProps) => {
  const portraitScreen = usePortraitScreen()

  const shouldShowPortraitVideo = useMemo(() => {
    if (desktopOnly) {
      return portraitScreen
    }

    return isSmallScreen
  }, [desktopOnly, isSmallScreen, portraitScreen])

  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load()
    }
  }, [portraitScreen])

  const vid = document.getElementById('sophieVideo')

  //loop video
  useEffect(() => {
    const restartVideo = () => {
      if (vid) {
        // @ts-ignore
        vid.currentTime = 0.1 //setting to zero breaks iOS 3.2, the value won't update, values smaller than 0.1 was causing bug as well.
        // @ts-ignore
        vid.play()
      }
    }
    // @ts-ignore
    vid && vid.addEventListener('ended', restartVideo, false)
    return () => {
      // @ts-ignore
      vid && vid.removeEventListener('ended', restartVideo)
    }
  }, [vid])

  return (
    <Box sx={{ position: 'relative' }}>
      {widgetMode && !isSmallScreen && renderLanguageSelector()}
      <Box
        sx={widgetMode ? styles.sophieVideoWidget : styles.sophieVideo}
        as="video"
        ref={videoRef}
        id="sophieVideo"
        playsInline
        autoPlay
        muted
        loop
        poster={shouldShowPortraitVideo ? portaitImg : landingImg}
      >
        <source
          src={shouldShowPortraitVideo ? portaitVideo : landingVideo}
          type="video/mp4"
        />
      </Box>
    </Box>
  )
}

interface HomeProps {
  startSession: (speak: boolean) => void
  restart?: () => void
  headingMessage: string
  disableDigitalHuman: boolean
  disableChatBot: boolean
  widgetMode?: boolean
  embeddedMode: boolean
  languageSelected: string
  supportedLanguages: LanguageOption[]
  showVideoBackground?: boolean
  shouldAcceptTerms?: boolean
  patientSelector?: boolean
  onManageFamilyMembers: () => void
  userData?: UserData
  setSid: (sid: string) => void
  setPatientSelected: (userData: SingleUserData) => void
  patientSelected: SingleUserData
}
const Home: React.FC<HomeProps> = ({
  startSession,
  restart = () => {},
  headingMessage,
  disableDigitalHuman,
  disableChatBot,
  widgetMode = false,
  embeddedMode,
  languageSelected,
  supportedLanguages,
  showVideoBackground,
  shouldAcceptTerms,
  patientSelector = false,
  onManageFamilyMembers,
  userData,
  setSid,
  setPatientSelected,
  patientSelected
}) => {
  const { isBrowserSupported } = useSupportedBrowser()
  const { tvAppMode } = useTvContext()
  const isSmallScreen = isTablet || isMobileOnly
  const { t } = useTranslation()
  const [showUnsupportedAlert, setShowUnsupportedAlert] = useState(false)
  const [acceptedToS, setAcceptedToS] = useState(true)
  // TODO update when API is integrated

  const seamlessLoginToken = userData && userData[0]?.preAuthToken
  const theme = useTheme()
  // Since this are the only modals not used with the DH
  // I'm leaving them to be opened with states, but if we need more modals
  // Outside the DH, we can create a new context for modals
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)
  const [showTermsOfConditions, setShowTermsOfConditions] = useState(false)

  const openPrivacyPolicy = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setShowPrivacyPolicy(true)
  }
  const openTOS = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setShowTermsOfConditions(true)
  }
  const patientIdString = patientSelected ? `&id=${patientSelected._id}` : ''
  const requestAnAppointmentLink = `${process.env.REACT_APP_FRONTEND_URL}/patient-request-appointment?token=${seamlessLoginToken}${patientIdString}&language=${languageSelected}`

  const StartButton = ({ speak, sx, ...props }: any) => {
    const handleClick =
      speak && !isBrowserSupported
        ? () => setShowUnsupportedAlert(true)
        : () => startSession(speak)

    return (
      <Button
        {...props}
        onClick={handleClick}
        sx={{ ...styles.letsChatButton, ...sx }}
      >
        {t(speak ? 'Home.audioButton' : 'Home.chatButton')}
      </Button>
    )
  }

  const LanguageSelectorComp = () => (
    <LanguageSelector
      languageSelected={languageSelected}
      supportedLanguages={supportedLanguages}
    />
  )

  const ToSText = () => (
    <Text sx={styles.subtitle}>
      <Trans
        i18nKey="Home.privacy"
        components={{
          privacylink: (
            <Box
              className="focusable"
              onClick={openPrivacyPolicy}
              onKeyDown={fnOnEnter(openPrivacyPolicy)}
              tabIndex={0}
              sx={styles.privacyPolicyLink}
            />
          ),
          termslink: (
            <Box
              className="focusable"
              onClick={openTOS}
              onKeyDown={fnOnEnter(openTOS)}
              tabIndex={0}
              sx={styles.privacyPolicyLink}
            />
          )
        }}
      />
    </Text>
  )
  const previousNotesLink = `${process.env.REACT_APP_FRONTEND_URL}/patient-dashboard?token=${seamlessLoginToken}${patientIdString}&language=${languageSelected}`

  return (
    <Flex
      sx={{
        ...styles.mainContainer,
        ...(widgetMode
          ? {
              justifyContent: 'flex-end',
              position: 'static',
              flexDirection: 'column'
            }
          : {})
      }}
    >
      {showVideoBackground && (
        <SophieVideo
          widgetMode={widgetMode}
          desktopOnly={embeddedMode}
          renderLanguageSelector={() => <LanguageSelectorComp />}
          isSmallScreen={isSmallScreen}
        />
      )}
      {showUnsupportedAlert && (
        <MayaUnsupportedBrowser
          close={() => setShowUnsupportedAlert(false)}
          continueChatMode={() => {
            setShowUnsupportedAlert(false)
            startSession(false)
          }}
        />
      )}
      {!widgetMode && !isSmallScreen && !tvAppMode && <LanguageSelectorComp />}
      <Flex
        variant="homeContainer"
        sx={{
          ...(widgetMode
            ? {
                m: 0
              }
            : {})
        }}
      >
        {isSmallScreen && !tvAppMode && (
          <LanguageSelector
            languageSelected={languageSelected}
            supportedLanguages={supportedLanguages}
            sx={{
              top: '0',
              transform: 'translateY(calc(-100% - 8px))',
              right: '0.5rem'
            }}
          />
        )}
        {userData && patientSelector && !patientSelected && (
          <PatientSelector
            userData={userData}
            onManageFamilyMembers={onManageFamilyMembers}
            setPatientSelected={setPatientSelected}
            setSid={setSid}
          />
        )}

        {/* Header for Aretaeio */}
        {userData && (!patientSelector || patientSelected) && (
          <Text fontSize={5}>
            <span>
              <Trans
                i18nKey="Home.welcome"
                values={{
                  userName: `${patientSelected?.firstName}`
                }}
                components={{ bold: <strong /> }}
              />
            </span>
          </Text>
        )}

        {/* Header for SB */}
        {!userData && (
          <Text variant="homeWelcome">
            <span>
              <Trans
                i18nKey="Home.welcome"
                values={{
                  userName: `${patientSelected?.firstName}`
                }}
                components={{ bold: <strong /> }}
              />
            </span>
            <span>{t('Home.headingMessage') || headingMessage}</span>
          </Text>
        )}

        {shouldAcceptTerms && (
          <Flex sx={styles.acceptTerms}>
            <Label id="checkboxContainer" sx={{ width: 'fit-content' }}>
              <Checkbox
                checked={acceptedToS}
                onClick={() => setAcceptedToS(!acceptedToS)}
                sx={{
                  mr: 6,
                  cursor: 'pointer',
                  'input:checked ~ &': {
                    color: (theme as any).colors.checkboxChecked
                  }
                }}
              />
            </Label>
            <ToSText />
          </Flex>
        )}
        {/* SB */}
        {headingMessage && !userData && (
          <Flex sx={styles.actions}>
            <Flex
              sx={{
                ...styles.mainActions,
                ...(!disableDigitalHuman ? styles.actionsWithBackButton : {})
              }}
            >
              {!disableDigitalHuman && (
                <StartButton
                  disabled={!acceptedToS}
                  id="audio"
                  variant="option"
                  speak={true}
                />
              )}
              {!disableChatBot && (
                <StartButton
                  disabled={!acceptedToS}
                  id="chat"
                  variant="startChatbot"
                  speak={false}
                />
              )}
            </Flex>
          </Flex>
        )}

        {/* Aretaeio */}
        {headingMessage && (!patientSelector || patientSelected) && (
          <Flex sx={styles.actions}>
            <Flex
              sx={{
                ...styles.mainActions,
                ...(patientSelected && styles.actionsWithBackButton)
              }}
            >
              {patientSelected && (
                <MayaRequestAppointmentButton
                  patientSelectedId={patientSelected._id}
                  preAuthToken={seamlessLoginToken}
                />
              )}
              {!disableChatBot && (
                <StartButton
                  disabled={!acceptedToS}
                  id="chat"
                  variant="option"
                  speak={false}
                />
              )}
              {!disableDigitalHuman && (
                <StartButton
                  disabled={!acceptedToS}
                  id="audio"
                  variant="option"
                  speak={true}
                />
              )}
              {patientSelected && (
                <Button
                  variant="viewNotes"
                  onClick={() => window.open(previousNotesLink, '_blank')}
                >
                  <NotesSvg />
                  {t('PatientSelector.viewNotes')}
                </Button>
              )}
            </Flex>
            {patientSelected && (
              <Button
                id="back"
                variant="icon"
                sx={styles.backButton}
                onClick={() => setPatientSelected(null)}
              >
                <BackIcon />
              </Button>
            )}
          </Flex>
        )}
        {!shouldAcceptTerms && (
          <Flex variant="homeTerms" mt={4}>
            <ToSText />
            {widgetMode && (
              <MayaCloseButton
                customStyles={{ alignSelf: 'center' }}
                onClick={restart}
              />
            )}
          </Flex>
        )}
      </Flex>
      {showPrivacyPolicy && (
        <MayaPrivacyPolicy onClose={() => setShowPrivacyPolicy(false)} />
      )}
      {showTermsOfConditions && (
        <MayaTermsOfConditions
          onClose={() => setShowTermsOfConditions(false)}
        />
      )}
    </Flex>
  )
}
export default Home
