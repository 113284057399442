export const styles = {
  container: {
    label: 'Authentication-container',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '75%',
    padding: '15px'
  }
}
