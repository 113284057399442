import { E164Number } from 'libphonenumber-js/types'
import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
import { Flex } from 'rebass'
import { LanguageOption } from '../../customPlugins/LanguageSelector/LanguageOption'
import LanguageSelector from '../../customPlugins/LanguageSelector/LanguageSelector'
import { styles } from './authenticationStyles'
import { CodeStep, PhoneStep } from './Steps'

type AuthenticationProps = {
  onRegisteredUserLogin: () => void
  onUnregisteredUserLogin: () => void
  languageSelected: string
  supportedLanguages: LanguageOption[]
  setUserPhone: any
  setUserData: any
  phoneLoginDefaultString?: string
}

export const Authentication = ({
  onRegisteredUserLogin,
  onUnregisteredUserLogin,
  languageSelected,
  supportedLanguages,
  setUserPhone,
  setUserData,
  phoneLoginDefaultString
}: AuthenticationProps) => {
  const [phone, setPhone] = useState<E164Number | undefined>(undefined)
  const [step, setStep] = useState<string>('phone')

  const onPhoneStepSuccess = () => {
    setStep('code')
  }

  return (
    <Flex sx={styles.container}>
      <LanguageSelector
        languageSelected={languageSelected}
        supportedLanguages={supportedLanguages}
      />
      {step === 'phone' && (
        <PhoneStep
          phone={phone}
          setPhone={setPhone}
          onSuccess={onPhoneStepSuccess}
          phoneLoginDefaultString={phoneLoginDefaultString}
        />
      )}
      {phone && step === 'code' && (
        <CodeStep
          setUserPhone={setUserPhone}
          setUserData={setUserData}
          phone={phone}
          back={() => setStep('phone')}
          onRegisteredUserLogin={onRegisteredUserLogin}
          onUnregisteredUserLogin={onUnregisteredUserLogin}
        />
      )}
    </Flex>
  )
}
