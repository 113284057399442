import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Steps } from '../app/App'
import { useChangeLanguage } from '../app/hooks/useChangeLanguage'
import { useDomainConfigContext } from '../app/hooks/useDomainConfigContext'
import { isBolivarSegurosPage } from '../bolivar-seguros'
import { LanguageInformation } from '../customPlugins/LanguageSelector/LanguageInformation'
import { LanguageOption } from '../customPlugins/LanguageSelector/LanguageOption'
import { getToken, setLanguageOnLocalStorage } from '../socket'
import { THEMES } from '../theme'

type ReponsibilityDisclaimer = {
  responsibilityDisclaimerText: string
  responsibilityDisclaimerImage: string
}

type UseApplicationStateProps = { embeddedApp?: boolean }

export type SingleUserData = {
  _id?: string | undefined
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  sex?: string
  email?: string
  phoneNumber?: string
  preAuthToken?: string
}

export type UserPhone = string | null
export type UserData = SingleUserData[] | null

type UseApplicationStateHook = (
  args: UseApplicationStateProps
) => {
  headingMessage: string
  logo: string
  disableDH: boolean
  initError: string
  currentLanguage: string
  supportedLanguages: LanguageOption[]
  personaId: string
  session: string | null
  disclaimer: string | null
  bgImage?: string
  bgMobileImage?: string
  responsibilityDisclaimer: ReponsibilityDisclaimer | null
  bgChatImage?: string
  shouldAcceptTerms: boolean
  activeTheme: THEMES
  hideTranscript: boolean
  phoneLoginDefaultString?: string
  initalStep?: string
  loadingMode?: string
  hideDownloadNotes: boolean
  loading: boolean
  uneeqURL?: string
  userPhone: UserPhone
  setUserPhone: any
  userData: UserData
  setUserData: any
  setSid: (sid: string) => void
  sid: string
  setPatientSelected: (userData: SingleUserData) => void
  patientSelected: SingleUserData
  initialStep?: Steps
  loadingMode?: string
}

export const useApplicationState: UseApplicationStateHook = ({
  embeddedApp
}) => {
  const mayaApiKey = process.env.REACT_APP_MAYA_API_KEY
  const mayaApiSecret = process.env.REACT_APP_MAYA_API_SECRET
  const { i18n } = useTranslation()
  const { dispatch: domainConfigDispatcher } = useDomainConfigContext()
  // For faster testing, skip straight to digitalHuman
  const [headingMessage, setHeadingMessage] = useState('')
  const [logo, setLogo] = useState('')
  const [loading, setLoading] = useState(true)
  const [disableDH, setDisableDH] = useState(false)
  const [disableCB, setDisableCB] = useState(false)
  const [initialDisableDH, setInitialDisableDH] = useState(false)
  const [initError, setInitError] = useState('')
  const [session, setSession] = useState('')
  const [currentLanguage, setCurrentLanguage] = useState('')
  const [disclaimer, setDisclaimer] = useState(null)
  const [supportedLanguages, setSupportedLanguages] = useState<
    LanguageOption[]
  >([])
  const [personaId, setPersonaId] = useState(
    process.env.REACT_APP_UNEEQ_PERSONA_ID || ''
  )
  const [uneeqURL, setUneeqURL] = useState(
    process.env.REACT_APP_UNEEQ_URL || ''
  )
  const [bgImage, setBgImage] = useState(undefined)
  const [bgMobileImage, setBgMobileImage] = useState(undefined)
  const [
    responsibilityDisclaimer,
    setResponsibilityDisclaimer
  ] = useState<ReponsibilityDisclaimer | null>(null)
  const [bgChatImage, setBgChatImage] = useState(undefined)
  const [shouldAcceptTerms, setShouldAcceptTerms] = useState(false)
  const [activeTheme, setActiveTheme] = useState(
    isBolivarSegurosPage() ? THEMES.SEGUROS_BOLIVAR : THEMES.DARK
  )
  const [hideTranscript, setHideTranscript] = useState(false)
  const [hideDownloadNotes, setHideDownloadNotes] = useState(false)
  const [userPhone, setUserPhone] = useState<UserPhone>(null)
  const [userData, setUserData] = useState<UserData>(null)
  const [sid, setSid] = useState<string | undefined>()
  const [patientSelected, setPatientSelected] = useState<SingleUserData | null>(
    null
  )
  const [phoneLoginDefaultString, setPhoneLoginDefaultString] = useState<
    string | undefined
  >(undefined)
  const [initialStep, setInitialStep] = useState<string | undefined>(undefined)
  const [loadingMode, setLoadingMode] = useState<string | undefined>(undefined)

  const setNewLanguage = useCallback(
    (lang: LanguageInformation) => {
      // add language bundle and overrides current one if already added
      const ISOLanguage = transformLanguageToISO(lang.key)
      i18n.addResourceBundle(
        ISOLanguage,
        'translation',
        lang.translation,
        true,
        true
      )
      setCurrentLanguage(ISOLanguage)
      setPersonaId(lang.personaId)
      setLanguageOnLocalStorage(lang.key)
    },
    [i18n]
  )

  const checkDigitalHumanAvailability = useCallback(
    (chosenLanguage: string, disableDHInitial: boolean) => {
      if (disableDHInitial) {
        return true
      }
      const supportedLanguageSelected = supportedLanguages.find(
        (lang: LanguageOption) => lang.code === chosenLanguage
      )

      if (supportedLanguageSelected?.digitalHuman === false) {
        return true
      }
      return false
    },
    [supportedLanguages]
  )

  const checkChatBotAvailability = useCallback(
    (chosenLanguage: string) => {
      const supportedLanguageSelected = supportedLanguages.find(
        (lang: LanguageOption) => lang.code === chosenLanguage
      )

      if (supportedLanguageSelected?.chatBot === false) {
        return true
      }
      return false
    },
    [supportedLanguages]
  )

  useChangeLanguage(setNewLanguage)

  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage)
      setDisableDH(
        checkDigitalHumanAvailability(currentLanguage, initialDisableDH)
      )
      setDisableCB(checkChatBotAvailability(currentLanguage))
    }
  }, [
    currentLanguage,
    checkDigitalHumanAvailability,
    i18n,
    initialDisableDH,
    checkChatBotAvailability
  ])

  useEffect(() => {
    getToken(mayaApiKey, mayaApiSecret, sid)
      .then(
        ({
          message,
          disableDigitalHuman,
          logo,
          title,
          i18n: i18nProps,
          supportedLanguages,
          session,
          disclaimer,
          uneeqURL,
          bgImage,
          bgMobileImage,
          bgChatImage,
          hideTranscript,
          hideDownloadNotes,
          responsibilityDisclaimerImage,
          responsibilityDisclaimerText,
          showLogoOnFlow,
          hideInfoActions,
          shouldAcceptTerms,
          phoneLoginDefaultString,
          initialStep,
          loadingMode,
          favicon,
          theme
        }: any) => {
          setLoading(false)
          const headingMessage = message || ' '
          setHeadingMessage(headingMessage)
          setInitialDisableDH(disableDigitalHuman)
          setLogo(logo)
          setUneeqURL(uneeqURL)

          setBgImage(bgImage)
          setBgMobileImage(bgMobileImage)
          setBgChatImage(bgChatImage)
          setHideTranscript(hideTranscript)
          setHideDownloadNotes(hideDownloadNotes)
          setResponsibilityDisclaimer({
            responsibilityDisclaimerText,
            responsibilityDisclaimerImage
          })
          setShouldAcceptTerms(shouldAcceptTerms)
          setPhoneLoginDefaultString(phoneLoginDefaultString)
          setInitialStep(initialStep)
          setLoadingMode(loadingMode)
          setActiveTheme(
            Object.values(THEMES).includes(theme) ? theme : THEMES.DARK
          )

          if (domainConfigDispatcher) {
            domainConfigDispatcher({
              type: 'SET_INITIAL_CONFIG',
              payload: {
                disclaimerImage: responsibilityDisclaimerImage || '',
                logo,
                showLogoOnFlow,
                hideInfoActions
              }
            })
          }

          if (title && !embeddedApp) {
            document.title = title
          }

          if (disclaimer) {
            setDisclaimer(disclaimer)
          }

          if (i18nProps) {
            setNewLanguage(i18nProps)
          }

          if (favicon) {
            const link = document.querySelector<HTMLLinkElement>(
              'link[rel="icon"]'
            )
            if (link) {
              link.href = favicon
            }
          }

          setSupportedLanguages(
            supportedLanguages.map((lang: any) => ({
              ...lang,
              code: transformLanguageToISO(lang.code)
            }))
          )

          if (session) {
            setSession(session)
          }
        }
      )
      .catch(({ message }: any) => {
        setInitError(message)
        setLoading(false)
      })
  }, [
    embeddedApp,
    setNewLanguage,
    domainConfigDispatcher,
    sid,
    mayaApiKey,
    mayaApiSecret
  ])

  return {
    headingMessage,
    logo,
    disableDH,
    initError,
    currentLanguage,
    supportedLanguages,
    personaId,
    session,
    disclaimer,
    uneeqURL,
    bgImage,
    bgMobileImage,
    responsibilityDisclaimer,
    bgChatImage,
    shouldAcceptTerms,
    activeTheme,
    hideTranscript,
    hideDownloadNotes,
    loading,
    userPhone,
    setUserPhone,
    userData,
    setUserData,
    setSid,
    sid,
    setPatientSelected,
    patientSelected,
    disableCB,
    phoneLoginDefaultString,
    initialStep,
    loadingMode
  }
}

export const transformLanguageToISO = (lang: string) => {
  if (lang && !lang.includes('-')) return lang
  const langSplit = (lang.split('-') as unknown) as string[]
  const location = langSplit[1].toUpperCase()
  return `${langSplit[0]}-${location}`
}
