export const styles = {
  loadingSpinner: {
    width: '100%',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'right bottom',
    backgroundColor: '#CCC',
    overflow: 'hidden',
    position: 'absolute',
    display: 'grid',
    placeContent: 'center',
    top: 0,
    left: 0
  },
  appBackground: (bgImage: string, bgMobileImage?: string) => ({
    backgroundImage: [
      `url(${bgMobileImage || bgImage})`,
      `url(${bgMobileImage || bgImage})`,
      `url(${bgMobileImage || bgImage})`,
      `url(${bgMobileImage || bgImage})`,
      `url(${bgImage})`
    ],
    backgroundPosition: [
      'center',
      'center',
      'center',
      'center',
      'center',
      'top'
    ]
  }),
  chatBackground: (bgChatImage: string) => ({
    backgroundImage: `url(${bgChatImage})`,
    backgroundSize: ['cover', 'cover', 'cover', 'contain', 'cover']
  })
}
