import { StorageKeys } from '../types/StorageKeys'

export const registerUserDataSession = userData => {
  const now = new Date()

  const apiUserDataSession = {
    value: userData,
    expiry: now.getTime() + 3600000 // Expiry session after 1 hour
  }

  localStorage.setItem(
    StorageKeys.API_USER_DATA,
    JSON.stringify(apiUserDataSession)
  )
}
