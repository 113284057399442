import * as _ from 'lodash'
import { aretaeioColors, aretaeioTheme } from './domains/aretaeio'
import {
  desertSpringsColors,
  desertSpringsTheme
} from './domains/desertSprings'
import {
  segurosBolivarColors,
  segurosBolivarTheme
} from './domains/segurosBolivar'

export enum THEMES {
  DEFAULT = 'DEFAULT',
  DARK = 'DARK',
  SEGUROS_BOLIVAR = 'SEGUROS_BOLIVAR',
  ARETAEIO = 'ARETAEIO',
  DESERT_SPRINGS = 'DESERT_SPRINGS'
}

const colors = {
  text: '#000000',
  textLight: '#393939',
  textAlternate: '#FFFFFF',
  textTranscript: '#000000',
  textTitle: '#000000',

  primary: '#275DAF',
  primaryLight: '#4287f5',
  primaryText: '#FFFFFF',

  secondary: '#22b5a1',
  secondaryLight: '#4bd6c4',
  secondaryText: '#FFFFFF',

  greyDark: '#50575D',
  grey: '#BDC0C7',
  greyLight: '#F6F6F6',

  informationDark: 'rgba(51,51,51,0.95)',
  informationLight: 'rgba(255, 255, 255, 0.2)',

  background: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimary: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimarySolid: 'rgba(255, 255, 255)',
  backgroundSecondary: 'rgba(51, 51, 51, 0.1)',
  backgroundSecondaryHover: 'rgba(51, 51, 51, 0.3)',
  backgroundOverlay: 'rgb(51, 51, 51, 0.5)',

  inputBackground: 'rgba(51, 51, 51, 0.1)',
  inputBackgroundHover: 'rgba(51, 51, 51, 0.1)',
  inputBorder: 'rgba(51, 51, 51, 0.1)',

  datePickerDayBg: 'rgba(51, 51, 51, 0.1)',
  datePickerDayColor: '#FFF',
  datePickerOverlayBg: 'rgba(255, 255, 255, 0.95)',

  optionSelectedBorder: 'rgba(0,0,0,0.4)',
  optionSelectedBorderHover: 'rgba(0,0,0,1)',
  optionSelectedBg: 'transparent',
  optionBorder: 'rgba(51, 51, 51, 0.1)',

  checkboxChecked: '#275DAF',

  link: 'currentColor',

  error: '#BF2828',
  warning: '#FBBD1D',

  // TODO create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#4287f5',
  timeoutTrailColor: '#BDC0C7',

  overlayBackground: 'rgba(0,0,0,0.7)',

  svgIconColor1: '#4287f5',
  svgIconColor2: '#ffffff'
}

const darkColors = {
  text: '#FFFFFF',
  textLight: '#393939',
  textAlternate: '#FFFFFF',
  textTranscript: '#FFFFFF',
  textTitle: '#FFFFFF',

  link: 'currentColor',

  primary: '#275DAF',
  primaryLight: '#4287f5',
  primaryText: '#FFFFFF',

  secondary: '#22b5a1',
  secondaryLight: '#4bd6c4',
  secondaryText: '#FFFFFF',

  greyDark: '#50575D',
  grey: '#BDC0C7',
  greyLight: '#F6F6F6',

  informationDark: 'rgba(51,51,51,0.95)',
  informationLight: 'rgba(255, 255, 255, 0.2)',

  background: 'rgba(51,51,51,0.95)',
  backgroundPrimary: 'rgba(51,51,51,0.95)',
  backgroundPrimarySolid: 'rgba(51,51,51)',
  backgroundSecondary: 'rgba(255, 255, 255, 0.1)',
  backgroundOverlay: 'rgb(255, 255, 255, 0.3)',

  backgroundSecondaryHover: 'rgba(255, 255, 255, 0.3)',

  inputBackground: 'rgba(255, 255, 255, 0.1)',
  inputBackgroundHover: 'rgba(255, 255, 255, 0.1)',
  inputBorder: 'rgba(255, 255, 255, 0.1)',

  optionSelectedBorder: 'rgba(255,255,255,0.4)',
  optionSelectedBorderHover: 'rgba(255,255,255,1)',
  optionSelectedBg: 'transparent',
  optionBorder: 'rgba(255, 255, 255, 0.1)',

  datePickerDayBg: 'rgba(255, 255, 255, 0.1)',
  datePickerDayColor: '#FFF',
  datePickerOverlayBg: 'rgba(51,51,51,0.95)',

  checkboxChecked: '#275DAF',

  error: '#BF2828',
  warning: '#FBBD1D',

  // TODO create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#4287f5',
  timeoutTrailColor: '#BDC0C7',

  overlayBackground: 'rgba(0,0,0,0.7)',

  svgIconColor1: '#4287f5',
  svgIconColor2: '#ffffff'
}

const hex2rgba = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

const themesColors: Record<THEMES, Record<string, string>> = {
  [THEMES.DARK]: darkColors,
  [THEMES.DEFAULT]: colors,
  [THEMES.SEGUROS_BOLIVAR]: segurosBolivarColors,
  [THEMES.ARETAEIO]: aretaeioColors,
  [THEMES.DESERT_SPRINGS]: desertSpringsColors
}

const baseTheme = (theme: THEMES, embeddedMode: boolean) => ({
  colors: {
    // ...(theme === THEMES.DARK ? darkColors : colors),
    ...themesColors[theme],

    // PTT animation
    pttMobileGlow: hex2rgba(colors.primaryLight, 0.2), // Mobile - glow around button while recording
    // PTT desktop colors & gradients
    pttColRecording: 'white',
    pttImgRecording: `radial-gradient(circle, ${colors.primaryLight} 0%, white 100%)`,
    pttColSending: 'primaryLight',
    pttImgSending: `radial-gradient(circle, white 0%,${colors.primaryLight} 100%)`,
    pttColWaiting: 'primaryLight',
    pttImgWaiting: 'primary'
  },
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    heading: 'inherit'
  },
  fontSizes: ['14px', '15px', '16px', '20px', '22px', '24px', '30px'],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: '31px'
  },
  space: [
    0,
    4,
    8,
    10,
    12,
    14,
    16,
    18,
    20,
    22,
    24,
    28,
    32,
    40,
    48,
    56,
    64,
    80,
    100,
    128
  ],
  borders: {
    focusOutline: 'none'
  },
  radii: {
    button: 2,
    buttonBig: 4,
    input: 1,
    card: 8
  },
  shadows: {
    card: '0 4px 4px rgba(0, 0, 0, 0.25)'
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5,
      pb: 2,
      mt: 3,
      mx: 4
    },
    title: {
      fontFamily: 'body',
      color: 'textTitle'
    },
    homeWelcome: {
      variant: 'text.title',
      fontSize: ['16px', '16px', '24px', '24px', '24px', '24px'],
      lineHeight: ['16px', '16px', '32px', '32px', '32px', '32px'],
      letterSpacing: '-0.01em'
    }
  },
  appStyles: {
    loadingSpinner: {
      width: '100%',
      height: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'right bottom',
      backgroundColor: '#CCC',
      overflow: 'hidden',
      position: 'absolute',
      display: 'grid',
      placeContent: 'center',
      top: 0,
      left: 0
    },
    appBackground: (bgImage: string, bgMobileImage?: string) => ({
      backgroundImage: [
        `url(${bgMobileImage || bgImage})`,
        `url(${bgMobileImage || bgImage})`,
        `url(${bgMobileImage || bgImage})`,
        `url(${bgMobileImage || bgImage})`,
        `url(${bgImage})`
      ],
      backgroundPosition: [
        'center',
        'center',
        'center',
        'center',
        'center',
        'top'
      ]
    }),
    chatBackground: (bgChatImage: string) => ({
      backgroundImage: `url(${bgChatImage})`,
      backgroundSize: ['cover', 'cover', 'cover', 'contain', 'cover']
    })
  },
  variants: {
    mainAppContainer: {
      label: 'wrapper',
      width: '100%',
      backgroundPosition: 'right bottom',
      backgroundColor: '#004C9C',
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundSize: ['cover', 'cover', 'cover', 'contain', 'cover']
    },
    link: {
      color: 'primary'
    },
    questionContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)'
    },
    customPositionedInteractionContainer: {
      left: ['unset', 'unset', 'unset', 'unset', '50%', '50%']
    },
    homeContainer: {
      flexDirection: 'column',
      alignSelf: 'flex-end',
      m: [0, 0, 0, 0, 6, 6],
      ml: [0, 0, 0, 0, 16, 16],
      backgroundColor: 'backgroundPrimary',
      borderRadius: ['unset', 'unset', 'unset', 'unset', '8px', '8px'],
      zIndex: 5,
      position: ['fixed', 'fixed', 'fixed', 'fixed', 'static', 'static'],
      bottom: [0, 0, 0, 0, 'unset', 'unset'],
      color: 'text',
      width: ['100%', '100%', '100%', '100%', 560, 560],
      p: [6, 6, 10, 10, 10, 10],
      backdropFilter: 'blur(50px)'
    },
    card: {
      bg: 'white',
      color: 'textLight',
      borderRadius: 'card',
      p: [6, 6, 12, 12, 12, 12],
      boxShadow: 'card'
    },
    modal: {
      variant: 'variants.card',
      label: 'modal',
      width: ['92%', '92%', 850],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    transcript: {
      label: 'Transcript',
      borderRadius: '3px',
      overflow: 'hidden',
      maxHeight: '99%',
      color: 'text',
      '& .transcript-header': {},
      '& .transcript-content': {
        fontSize: ['14px', '14px', '18px', '18px', '18px', '18px'],
        px: 5,
        pb: 5,
        height: ['100%', '100%', '100%', '100%', 'auto', 'auto']
      },
      '& .transcript-footer': {
        height: 45,
        position: ['fixed', 'fixed', 'fixed', 'fixed', 'static'],
        minHeight: '45px',
        bottom: [0, 0, 0, 0, 'auto', 'auto'],
        backgroundColor: 'white',
        width: '100%',
        p: 2
      }
    },
    inputSelect: {
      outline: 'none',
      border: 'none',
      width: 'max-content',
      '& option': {
        color: 'text',
        bg: 'background'
      },
      color: 'text',
      fontSize: 0,
      lineHeight: 'body',
      appearance: 'none',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      bg: 'background',
      fontFamily: 'inherit',
      ':focus': {
        outline: 'none'
      },
      pr: 10,
      py: 1,
      pl: 2
    },
    homeLogo: {
      backgroundColor: [
        'rgba(0,0,0,0.3)',
        'rgba(0,0,0,0.3)',
        'rgba(0,0,0,0.3)',
        'rgba(0,0,0,0.3)',
        'transparent',
        'transparent'
      ],
      maxWidth: ['unset', 'unset', 'unset', 'unset', '30vw', '30vw'],
      width: ['100%', '100%', '100%', '100%', 'auto', 'auto'],
      zIndex: '4',
      position: 'absolute',
      top: 0,
      left: [0, 0, 0, 0, '16px', '16px'],
      display: 'flex',
      justifyContent: [
        'center',
        'center',
        'center',
        'center',
        'flex-start',
        'flex-start'
      ],
      alignItems: [
        'center',
        'center',
        'center',
        'center',
        'flex-start',
        'flex-start'
      ],
      '& img': {
        mt: ['16px', '16px', '16px', '16px', '32px', '32px'],
        display: 'block',
        width: ['70%', '70%', '70%', '70%', 'auto', 'auto'],
        p: 2
      }
    },
    informationButtonsContainer: {
      maxHeight: ['20vh', '20vh', '20vh', '20vh', '80%', '80%'],
      overflowY: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      flex: 1,
      alignItems: 'center',
      mb: 2,
      '& > button': {
        mb: 2
      },
      '& > *:not(:last-child)': {
        mr: 2
      },
      '::-webkit-scrollbar': {
        bg: 'backgroundPrimary',
        height: '8px'
      },
      '::-webkit-scrollbar-thumb': {
        bg: 'text',
        borderRadius: '100px'
      },
      '::-webkit-scrollbar-track': {
        bg: 'backgroundPrimary'
      }
    },
    privacyPolicyLink: {
      textDecoration: 'underline',
      color: 'link',
      '&:focus': {
        border: 'focusOutline'
      }
    },
    languageSelectorSelectContainer: {
      zIndex: 99,
      position: 'absolute',
      top: '2rem',
      right: '51px',
      bg: 'backgroundPrimary',
      color: 'text',
      backdropFilter: 'blur(50px)',
      display: 'flex',
      borderRadius: 'buttonBig',
      alignItems: 'center'
    }
  },
  buttons: {
    // TODO
    unstyled: {
      background: 'transparent',
      color: 'secondary',
      fontFamily: 'inherit',
      fontSize: 0
    },
    primary: {
      fontFamily: 'inherit',
      borderRadius: '4px',
      fontWeight: '600',
      fontSize: ['11px', '11px', '14px', '14px', '14px', '14px'],
      lineHeight: ['13px', '13px', '17px', '17px', '17px', '17px'],
      p: '8px',
      transition: 'all 0.4s',
      cursor: 'pointer',
      backgroundColor: 'primary',
      border: '1px solid transparent',
      borderColor: 'primary',
      whiteSpace: 'nowrap',
      '& svg, & img': {
        fill: 'currentColor'
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'primaryText',
      '& img, & svg': {
        mr: 2
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'primaryLight'
        }
      },
      '&:focus': {
        outline: '1px solid white'
        // backgroundColor: 'primary',
        // '--box-shadow-color': 'primary',
        // boxShadow: '0 0 3px var(--box-shadow-color)',
        // borderColor: 'primaryLight'
      },
      '&:disabled': {
        opacity: 0.2
      }
    },
    primaryInverted: {
      variant: 'buttons.primary',
      bg: 'black',
      borderColor: 'primary',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'rgba(1, 99, 172, 0.6)'
        }
      },
      '&:focus': {
        outline: 'none',
        '--box-shadow-color': 'primary',
        boxShadow: '0 0 3px var(--box-shadow-color)'
      },
      '&:disabled': {
        borderColor: 'grey',
        color: '#E3E5E8'
      }
    },
    secondaryInverted: {
      variant: 'buttons.secondary',
      color: 'secondary',
      background: 'white',
      borderColor: 'secondary',
      '& svg, & img': {
        // fill: 'currentColor'
        fill: 'red'
      },
      '& img, & svg': {
        // mr: 2
        mr: 20
        // TODO
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: '#2E2818'
        }
      },
      '&:focus': {
        borderColor: 'secondary',
        '--box-shadow-color': 'secondary',
        boxShadow: '0 0 3px var(--box-shadow-color)'
      },
      '&:disabled': {
        borderColor: 'greyDark',
        color: '#85898E'
      }
    },
    outline: {
      color: 'textAlternate',
      fontWeight: '300',
      cursor: 'pointer',
      border: '1px solid #FFFFFF',
      transition: 'all 1s',
      backgroundColor: 'transparent',
      borderRadius: 50,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.1)'
        }
      },
      '&:focus': {
        outline: 'none',
        border: '1px solid transparent',
        borderColor: 'white',
        '--box-shadow-color': 'transparent',
        boxShadow: 'none'
      },
      '&:disabled': {
        backgroundColor: 'thirdGray',
        color: 'fourthGray'
      }
    },
    white: {
      fontFamily: 'inherit',
      borderRadius: 'button',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '-0.01em',
      p: '8px',
      transition: 'all 0.4s',
      cursor: 'pointer',
      backgroundColor: '#fff',
      border: '1px solid transparent',
      whiteSpace: 'nowrap',
      '& svg, & img': {
        fill: 'currentColor'
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#333333',
      '& img, & svg': {
        mr: 2
      },
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.7)'
        }
      },
      '&:focus': {
        backgroundColor: '#fff',
        '--box-shadow-color': 'primary',
        boxShadow: '0 0 3px var(--box-shadow-color)'
      },
      '&:disabled': {
        opacity: 0.2
      }
    },
    whiteInverted: {
      fontFamily: 'inherit',
      borderRadius: '4px',
      fontWeight: 'normal',
      fontSize: ['11px', '11px', '14px', '14px', '14px', '14px'],
      lineHeight: ['13px', '13px', '17px', '17px', '17px', '17px'],
      letterSpacing: '-0.01em',
      p: '8px',
      transition: 'all 0.4s',
      cursor: 'pointer',
      bg: 'backgroundSecondary',
      border: '1px solid transparent',
      whiteSpace: 'nowrap',
      '& svg, & img': {
        fill: 'currentColor'
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'text',
      '& img, & svg': {
        mr: 2
      },
      '@media (hover: hover)': {
        '&:hover': {
          bg: 'backgroundSecondaryHover'
        }
      },
      ':focus': {
        border: '1px solid white'
        // outline: '1px solid white'
        // bg: 'backgroundSecondary',
        // '--box-shadow-color': 'primary',
        // boxShadow: '0 0 3px var(--box-shadow-color)'
      },
      '&:disabled': {
        opacity: 0.2
      }
    },
    secondary: {
      variant: 'buttons.whiteInverted'
    },
    select: {
      variant: 'buttons.unstyled',
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: 'backgroundSecondary',
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: '16px',
      paddingRight: '16px',
      width: '100%',
      textAlign: 'left',
      color: 'text',
      cursor: 'pointer',
      whiteSpace: 'pre-wrap',
      transition: 'border .4s, color .4s, background .4s',
      ':focus': {
        outline: 'none'
      }
    },
    icon: {
      stroke: 'text',
      width: 'auto',
      p: 2,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      backgroundColor: 'backgroundSecondary',
      borderRadius: 'buttonBig',
      cursor: 'pointer',
      minWidth: 'unset',
      transition: 'all .2s',
      '& svg': {
        minWidth: 18,
        height: 18,
        margin: 0
      },
      '&:hover': {
        backgroundColor: 'backgroundSecondaryHover'
      },
      '&:focus': {
        border: 'focusOutline'
      }
    },
    information: {
      variant: 'buttons.whiteInverted',
      width: 'fit-content',
      py: 2,
      px: 7,
      ml: 0,
      minHeight: 37,
      fontWeight: 700,
      minWidth: 'auto'
    },
    informationImportant: {
      variant: 'buttons.primary',
      width: 'fit-content',
      py: 2,
      px: 7,
      ml: 0,
      minHeight: 37,
      fontWeight: 700,
      minWidth: 'auto'
    },
    alertPrimary: {
      variant: 'buttons.primary'
    },
    alertInverted: {
      variant: 'buttons.whiteInverted'
    },
    startChatbot: {
      variant: 'buttons.whiteInverted',
      fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
      lineHeight: ['17px', '17px', '19px', '19px', '19px', '19px'],
      py: [3, 2],
      px: [6, 5]
    },
    multiSelectSubmitButton: {
      fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
      lineHeight: ['17px', '17px', '19px', '19px', '19px', '19px'],
      fontWeight: 700,
      minWidth: 'unset',
      width: 'fit-content',
      py: 2,
      px: 7,
      ml: 0,
      mt: 12,
      display: 'flex',
      alignSelf: 'flex-end',
      mb: '2px',
      mr: '2px'
    }
  },
  breakpoints: embeddedMode
    ? ['1px', '2px', '3px', '4px', '5px', '6px']
    : ['320px', '600px', '768px', '1025px', '1280px', '1440px'],
  zIndices: {
    transcript: 5,
    themeSelector: 4
  }
})

const tvTheme = (type: THEMES, embeddedMode: boolean) => ({
  borders: {
    focusOutline: '1px solid white'
  },
  outlines: {
    focusOutline: '1px solid white'
  },
  buttons: {
    startChatbot: {
      fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
      lineHeight: ['17px', '17px', '19px', '19px', '19px', '19px'],
      fontWeight: 'normal',
      py: [3, 2],
      px: [6, 5],
      '&:focus': {
        outline: '1px solid #fff'
      }
    },
    select: {
      ':focus': {
        outline: 'none',
        backgroundColor: 'transparent',
        border: '1px solid white'
      }
    },
    multiSelectSubmitButton: {
      fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
      lineHeight: ['17px', '17px', '19px', '19px', '19px', '19px'],
      fontWeight: 700,
      minWidth: 'unset',
      width: 'fit-content',
      py: 2,
      px: 7,
      ml: 0,
      mt: 12,
      display: 'flex',
      alignSelf: 'flex-end',
      mb: '2px',
      mr: '2px',
      '&:focus': {
        border: '1px solid white'
      }
    }
  },
  variants: {
    privacyPolicyLink: {
      textDecoration: 'underline',
      color: 'link',
      '&:focus': {
        border: 'focusOutline',
        padding: '3px 0',
        borderRadius: 'button'
      }
    }
  }
})

const mergeThemes = (
  type: THEMES,
  embeddedMode: boolean,
  theme: (type: THEMES, embeddedMode: boolean) => { [x: string]: any }
) => {
  return _.merge(baseTheme(type, embeddedMode), theme(type, embeddedMode))
}

const theme = (type: THEMES, embeddedMode: boolean) => {
  if (type === THEMES.SEGUROS_BOLIVAR) {
    return mergeThemes(type, embeddedMode, segurosBolivarTheme)
  }

  if (type === THEMES.DESERT_SPRINGS) {
    // T-3332 improve this function for wider use
    return mergeThemes(type, embeddedMode, desertSpringsTheme)
  }

  if (type === THEMES.ARETAEIO) {
    return mergeThemes(type, embeddedMode, aretaeioTheme)
  }

  if (process.env.REACT_APP_BUILD_MODE === 'tv') {
    return mergeThemes(type, embeddedMode, tvTheme)
  }

  return baseTheme(type, embeddedMode)
}

export default theme
