import { THEMES } from '../theme'

export const segurosBolivarColors = {
  text: '#000000',
  textLight: '#393939',
  textAlternate: '#FFFFFF',
  textTranscript: '#FFF',
  textTitle: '#016D38',

  primary: '#FFDC5D',
  primaryLight: '#88FFE1',
  primaryText: '#016D38',

  secondary: '#FFFFFF',
  secondaryLight: '#4bd6c4',
  secondaryText: '#016D38',

  greyDark: '#50575D',
  grey: '#BDC0C7',
  greyLight: '#F6F6F6',

  informationDark: 'rgba(51,51,51,0.95)',
  informationLight: 'rgba(255, 255, 255, 0.2)',

  background: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimary: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimarySolid: 'rgba(255, 255, 255)',
  backgroundSecondary: '#016D38',
  backgroundSecondaryHover: 'rgba(51, 51, 51, 0.3)',
  backgroundOverlay: 'rgb(51, 51, 51, 0.5)',
  error: '#BF2828',
  warning: '#FBBD1D',

  inputBackground: '#FFFFFF',
  inputBackgroundHover: '#FFFFFF',
  inputBorder: '#CCCCCC',

  btnPrimaryBg: '#FFDC5D',
  btnPrimaryBgHover: '#FFD333',

  btnWhiteInverted: '#016D38',
  btnWhiteInvertedHover: '#005833',

  btnSecondaryBg: '#F0F6F3',
  btnSecondaryBgHover: '#E6F0EB',

  optionSelectedBorder: '#016D38',
  optionBorder: '#CCCCCC',
  optionText: '#999999',
  optionSelectedBg: '#F0F6F3',
  optionSelectedText: '#333333',

  datePickerDayBg: '#FFDC5D',
  datePickerDayColor: '#000',
  datePickerOverlayBg: '#FFF',

  checkboxChecked: '#016D38',

  link: '#016D38',

  // TODO create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#FFDC5D',
  timeoutTrailColor: '#FBFBFB',

  overlayBackground: 'rgba(0,0,0,0.7)'
}

export const segurosBolivarTheme = (type: THEMES, embeddedMode: boolean) => ({
  fonts: {
    body: "'Roboto Condensed', sans-serif",
    heading: 'inherit'
  },
  buttons: {
    primary: {
      borderRadius: '999px',
      fontWeight: 'bold',
      bg: 'btnPrimaryBg',
      '&:hover': {
        bg: 'btnPrimaryBgHover',
        borderColor: 'transparent'
      },
      '&:active': {
        boxShadow: 'inset 2px 2px 3px rgba(0, 0, 0, 0.16)',
        borderColor: 'transparent'
      },
      '&:focus': {
        borderColor: '#DDCC85'
      },
      '&:disabled': {
        bg: '#EEEEEE',
        color: '#999999'
      }
    },
    whiteInverted: {
      fontWeight: 'bold',
      bg: 'white',
      borderRadius: '100px',
      border: '1px solid',
      color: 'btnWhiteInverted',
      borderColor: 'btnWhiteInverted',
      '&:hover': {
        color: 'btnWhiteInvertedHover',
        borderColor: 'btnWhiteInvertedHover',
        bg: 'white'
      },
      '&:active': {
        boxShadow: 'inset 2px 2px 3px rgba(11, 87, 30, 0.25)'
      },
      '&:focus': {
        bg: 'white'
      },
      '&:disabled': {
        bg: '#EEEEEE',
        color: '#999999'
      }
    },
    secondary: {
      bg: 'btnSecondaryBg',
      color: 'primaryText',
      border: '1px solid',
      borderColor: 'transparent',
      '&:hover': {
        bg: 'btnSecondaryBgHover'
      },
      '&:active': {
        boxShadow: 'inset 2px 2px 3px rgba(11, 87, 30, 0.25)'
      },
      '&:focus': {
        bg: 'btnSecondaryBg',
        borderColor: '#ADE4C9'
      },
      '&:disabled': {
        borderColor: '#999999',
        color: '#999999'
      }
    },
    select: {
      border: '1px solid',
      borderColor: 'optionBorder',
      borderRadius: '5px',
      color: 'optionSelectedText',
      '&:hover': {
        color: 'optionSelectedText',
        borderColor: [
          'optionBorder',
          'optionBorder',
          'optionBorder',
          'optionBorder',
          'optionText',
          'optionText'
        ]
      },
      '&:active': {
        borderColor: 'optionSelectedBorder',
        color: 'optionSelectedText',
        bg: 'optionSelectedBg'
      }
    },
    icon: {
      borderRadius: '100px',
      bg: 'btnSecondaryBg',
      color: 'primaryText',
      border: '1px solid',
      borderColor: 'transparent',
      '&:hover': {
        bg: 'btnSecondaryBgHover'
      },
      '&:active': {
        boxShadow: 'inset 2px 2px 3px rgba(11, 87, 30, 0.25)'
      },
      '&:focus': {
        bg: 'btnSecondaryBg',
        borderColor: '#ADE4C9'
      },
      '&:disabled': {
        borderColor: '#999999',
        color: '#999999'
      }
    },
    information: {
      flex: [1, 1, 1, 1, 'unset'],
      px: [7, 7, 7, 7, 14]
    },
    informationImportant: {
      flex: [1, 1, 1, 1, 'unset'],
      px: [7, 7, 7, 7, 14]
    },
    alertPrimary: {
      variant: 'buttons.primary',
      px: [10, 10, 10, 10, 14],
      py: [3, 3, 3, 3, 3, 3],
      fontSize: '14px',
      lineHeight: '20px',
      flex: [1, 1, 1, 1, 'unset', 'unset']
    },
    alertInverted: {
      variant: 'buttons.whiteInverted',
      px: [10, 10, 10, 10, 14],
      py: [3, 3, 3, 3, 3, 3],
      fontSize: '14px',
      lineHeight: '20px',
      flex: [1, 1, 1, 1, 'unset', 'unset']
    },
    startChatbot: {
      variant: 'buttons.primary',
      margin: '0 auto',
      px: [10, 10, 10, 10, 14, 14]
    }
  },
  variants: {
    questionContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)'
    },
    homeTerms: {
      display: ['none', 'none', 'none', 'none', 'none']
    },
    transcriptContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)'
    },
    inputSelect: {
      border: '1px solid #CCCCCC',
      pl: 6,
      pr: 10,
      py: 3,
      borderRadius: '4px'
    },
    homeLogo: {
      backgroundColor: [
        'transparent',
        'transparent',
        'transparent',
        'transparent',
        'transparent',
        'transparent'
      ],
      maxWidth: ['unset', 'unset', 'unset', 'unset', '20vw', '20vw'],
      width: ['60%', '60%', '60%', '60%', 'auto', 'auto'],
      zIndex: '4',
      position: 'absolute',
      top: 0,
      left: ['16px', '16px', '16px', '16px', '24px', '24px'],
      display: 'flex',
      justifyContent: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start'
      ],
      alignItems: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start'
      ],
      '& img': {
        mt: ['0px', '0px', '0px', '0px', '0px', '0px'],
        display: 'block',
        width: ['70%', '70%', '50%', '50%', 'auto', 'auto'],
        p: 2
      }
    },
    progressContainer: {
      '& div': { height: '14px' },
      '& > div': {
        border: '1px solid #CCCCCC',
        borderRadius: '100px',
        overflow: 'hidden'
      }
    },
    footerDisclaimer: {
      height: '48px',
      position: 'relative',
      display: ['block', 'block', 'block', 'block', 'none', 'none'],
      '& > img': {
        position: 'absolute',
        mixBlendMode: 'difference',
        transform: 'rotate(90deg) translate(50%, -50%)',
        transformOrigin: 'top',
        top: '24px',
        left: '50%',
        height: '200px',
        opacity: '0.5'
      }
    },
    informationButtonsContainer: {
      justifyContent: 'center'
    },
    homeContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
      mb: ['0', '0', '0', '0', '112px', '112px']
    }
  },
  text: {
    title: {
      fontWeight: 700
    },
    homeWelcome: {
      '& > span:nth-child(1)': {
        fontWeight: 'bold'
      },
      '& > span:nth-child(2)': {
        mt: [2, 2, 2, 2, 6, 6],
        fontSize: [0, 0, 2, 2, 2, 2],
        color: 'text',
        display: 'block',
        fontWeight: 'normal'
      }
    }
  }
})
