import { THEMES } from '../theme'

export const aretaeioColors = {
  text: '#000000',
  textLight: '#393939',
  textAlternate: '#FFFFFF',
  textTranscript: '#FFF',
  textTitle: '#111111',

  primary: '#95222F',
  primaryLight: '#F0EBEB',
  primaryText: '#fff',

  secondary: '#FFFFFF',
  secondaryLight: '#F0EBEB',
  secondaryText: '#111111',

  greyDark: '#50575D',
  grey: '#BDC0C7',
  greyLight: '#F6F6F6',

  informationDark: 'rgba(51,51,51,0.95)',
  informationLight: 'rgba(255, 255, 255, 0.2)',

  background: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimary: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimarySolid: 'rgba(255, 255, 255)',
  backgroundSecondary: '#95222F',
  backgroundSecondaryHover: 'rgba(51, 51, 51, 0.3)',
  backgroundOverlay: 'rgb(51, 51, 51, 0.5)',
  backgroundError: '#FDE9E6',
  error: '#BA1701',
  warning: '#FBBD1D',
  success: '#56AF60',

  inputBackground: '#FCFCFC',
  inputBackgroundHover: '#FFFFFF',
  inputBorder: '#E1E1E1',

  btnPrimaryBg: '#95222F',
  btnPrimaryBgHover: '#95222F',

  btnWhiteInverted: '#000',
  btnWhiteInvertedHover: '#000',

  btnSecondaryBg: 'rgba(17, 17, 17, 0.1)',
  btnSecondaryBgHover: 'rgba(17, 17, 17, 0.1)',

  optionSelectedBorder: '#111111',
  optionBorder: '#CCCCCC',
  optionText: '#999999',
  optionSelectedBg: '#EBEBEB',
  optionSelectedText: '#333333',

  datePickerDayBg: '#F0EBEB',
  datePickerDayColor: '#000',
  datePickerOverlayBg: '#FFF',

  checkboxChecked: '#95222F',

  link: '#95222F',

  // TODO create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#95222F',
  timeoutTrailColor: '#FBFBFB',

  overlayBackground: 'rgba(0,0,0,0.7)'
}

export const aretaeioTheme = (type: THEMES, embeddedMode: boolean) => ({
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'inherit'
  },
  borders: {
    card: '1px solid #E1E1E1',
    default: '1px solid #D4D8DC',
    focused: '1px solid #000000', // TODO: NEW value. only use if MayaRed
    error: '1px solid #EF1919'
  },
  buttons: {
    primary: {
      borderRadius: 0,
      fontSize: 16,
      fontWeight: 500,
      p: 3,
      cursor: 'pointer',
      bg: 'btnPrimaryBg',
      '&:hover': {
        bg: 'btnPrimaryBgHover'
      },
      '&:disabled': {
        opacity: 0.4,
        cursor: 'not-allowed'
      }
    },
    whiteInverted: {
      borderRadius: '0px',
      fontSize: 14,
      bg: 'rgba(71, 76, 79, 0.1)',
      border: '1px solid',
      color: 'btnWhiteInverted',
      borderColor: 'transparent',
      fontWeight: 400,
      '&:hover': {
        color: 'btnWhiteInvertedHover',
        borderColor: 'transparent',
        bg: 'rgba(71, 76, 79, 0.1)'
      },
      '&:active': {
        boxShadow: 'inset 2px 2px 3px rgba(11, 87, 30, 0.25)'
      },
      '&:focus': {
        bg: 'white'
      },
      '&:disabled': {
        bg: '#EEEEEE',
        color: '#999999'
      }
    },
    secondary: {
      bg: 'btnSecondaryBg',
      fontSize: 14,
      color: 'secondaryText',
      border: '1px solid',
      borderColor: 'transparent',
      cursor: 'pointer',
      borderRadius: 0,
      '&:hover': {
        bg: 'btnSecondaryBgHover'
      },
      '&:focus': {
        bg: 'btnSecondaryBg'
      },
      '&:disabled': {
        opacity: 0.4,
        cursor: 'not-allowed'
      }
    },
    select: {
      border: '1px solid',
      borderColor: 'optionBorder',
      borderRadius: '5px',
      color: 'optionSelectedText',
      '&:hover': {
        color: 'optionSelectedText',
        borderColor: [
          'optionBorder',
          'optionBorder',
          'optionBorder',
          'optionBorder',
          'optionText',
          'optionText'
        ]
      },
      '&:active': {
        borderColor: 'optionSelectedBorder',
        color: 'optionSelectedText',
        bg: 'optionSelectedBg'
      }
    },
    icon: {
      bg: 'btnSecondaryBg',
      maxWidth: 36,
      color: 'secondaryText',
      '& svg': {
        fill: 'secondaryText'
      },
      border: '1px solid',
      borderColor: 'transparent',
      '&:hover': {
        bg: 'btnSecondaryBgHover'
      },
      '&:active': {
        boxShadow: 'inset 2px 2px 3px optionSelectedBorder'
      },
      '&:focus': {
        bg: 'btnSecondaryBg',
        borderColor: 'optionSelectedBorder'
      },
      '&:disabled': {
        borderColor: '#999999',
        color: '#999999'
      }
    },
    information: {
      flex: [1, 1, 1, 1, 'unset'],
      px: [7, 7, 7, 7, 14]
    },
    informationImportant: {
      flex: [1, 1, 1, 1, 'unset'],
      px: [7, 7, 7, 7, 14]
    },
    alertPrimary: {
      variant: 'buttons.primary',
      px: [10, 10, 10, 10, 14],
      py: [3, 3, 3, 3, 3, 3],
      fontSize: '14px',
      lineHeight: '20px',
      flex: [1, 1, 1, 1, 'unset', 'unset']
    },
    alertInverted: {
      variant: 'buttons.whiteInverted',
      px: [10, 10, 10, 10, 14],
      py: [3, 3, 3, 3, 3, 3],
      fontSize: '14px',
      lineHeight: '20px',
      flex: [1, 1, 1, 1, 'unset', 'unset']
    },
    letsChatButton: {},
    option: {
      label: 'Button-option',
      cursor: 'pointer',
      p: 6,
      textAlign: 'left',
      backgroundColor: 'transparent',
      borderRadius: 0,
      color: 'text',
      border: 'default'
    },
    viewNotes: {
      variant: 'buttons.secondary',
      label: 'Button-viewNotes',
      background: 'rgba(149, 34, 47, 0.1)',
      '&:hover': { background: 'rgba(149, 34, 47, 0.1)' },
      color: 'primary'
    }
  },
  forms: {
    input: {
      bg: '#FCFCFC',
      boxSizing: 'border-box',
      fontSize: '16px',
      p: '0.5rem',
      borderRadius: 0,
      border: '1px solid #E1E1E1',
      display: 'flex',
      cursor: 'text',
      '&.errorInput': {
        border: '1px solid #EF1919'
      },
      '&.form-input': {
        '&:focus, :focus-visible': {
          outline: 'unset',
          border: '1px solid #000000'
        }
      },
      '& > div': {
        width: '100%'
      },
      '&:focus, :focus-visible': {
        outline: 'unset'
      },
      '& ::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0
      },
      '&:disabled': {
        opacity: 0.4,
        cursor: 'not-allowed'
      }
    },
    select: {
      border: '1px solid #E1E1E1',
      '&.errorInput': {
        border: '1px solid #EF1919'
      },
      '&:focus, :focus-visible': {
        outline: 'unset',
        border: '1px solid #000000'
      }
    }
  },
  appStyles: {
    loadingSpinner: {
      width: '100%',
      height: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'right bottom',
      backgroundColor: '#CCC',
      overflow: 'hidden',
      position: 'absolute',
      display: 'grid',
      placeContent: 'center',
      top: 0,
      left: 0
    },
    appBackground: (bgImage: string, bgMobileImage?: string) => ({
      backgroundImage: [
        `url(${bgMobileImage || bgImage})`,
        `url(${bgMobileImage || bgImage})`,
        `url(${bgMobileImage || bgImage})`,
        `url(${bgMobileImage || bgImage})`,
        `url(${bgImage})`
      ],
      backgroundPosition: [
        'center',
        'center',
        'center',
        'right',
        'right',
        'right'
      ]
    }),
    chatBackground: (bgChatImage: string) => ({
      backgroundImage: `url(${bgChatImage})`,
      backgroundSize: ['cover', 'cover', 'cover', 'contain', 'cover']
    })
  },
  variants: {
    mainAppContainer: {
      background:
        'linear-gradient(138.53deg, #FFFFFF 8.63%, #F0EBEB 82.68%), #FCFCFC',
      backgroundRepeat: 'no-repeat',
      backgroundSize: ['cover', 'cover', 'cover', 'contain', 'contain']
    },
    questionContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)'
    },
    customPositionedInteractionContainer: {
      left: ['unset', 'unset', 'unset', 'unset', '23%', '23%']
    },
    homeTerms: {
      display: ['none', 'none', 'none', 'none', 'none']
    },
    transcriptContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)'
    },
    inputSelect: {
      border: '1px solid #CCCCCC',
      pl: 6,
      pr: 10,
      py: 3,
      borderRadius: '4px'
    },
    homeLogo: {
      backgroundColor: [
        'transparent',
        'transparent',
        'transparent',
        'transparent',
        'transparent',
        'transparent'
      ],
      maxWidth: ['unset', 'unset', 'unset', 'unset', '20vw', '20vw'],
      width: ['60%', '60%', '60%', '60%', 'auto', 'auto'],
      zIndex: '4',
      position: ['initial', 'initial', 'absolute'],
      top: 0,
      left: ['16px', '16px', '16px', '16px', '24px', '24px'],
      display: 'flex',
      justifyContent: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start'
      ],
      alignItems: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start'
      ],
      '& img': {
        mt: ['0px', '0px', '0px', '0px', '0px', '0px'],
        display: 'block',
        width: ['70%', '70%', '50%', '50%', 'auto', 'auto'],
        p: 2
      }
    },
    progressContainer: {
      '& div': { height: '14px' },
      '& > div': {
        border: '1px solid #CCCCCC',
        borderRadius: '100px',
        overflow: 'hidden'
      }
    },
    footerDisclaimer: {
      height: '48px',
      position: 'relative',
      display: ['block', 'block', 'block', 'block', 'none', 'none'],
      '& > img': {
        position: 'absolute',
        mixBlendMode: 'difference',
        transform: 'rotate(90deg) translate(50%, -50%)',
        transformOrigin: 'top',
        top: '24px',
        left: '50%',
        height: '200px',
        opacity: '0.5'
      }
    },
    informationButtonsContainer: {
      justifyContent: 'center'
    },
    homeContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
      mb: ['0', '0', '0', '0', '112px', '112px']
    },
    languageSelectorSelectContainer: {
      zIndex: 99,
      position: 'absolute',
      top: '20px',
      right: '18px',
      bg: 'backgroundPrimary',
      color: 'text',
      backdropFilter: 'blur(50px)',
      display: 'flex',
      borderRadius: 'buttonBig',
      alignItems: 'center'
    }
  },
  radii: {
    button: 0,
    buttonBig: 0,
    input: 0,
    card: 0
  },
  text: {
    title: {
      fontWeight: 400
    },
    homeWelcome: {
      '& > span:nth-child(1)': {
        fontWeight: 'bold'
      },
      '& > span:nth-child(2)': {
        mt: [2, 2, 2, 2, 6, 6],
        fontSize: [0, 0, 2, 2, 2, 2],
        color: 'text',
        display: 'block',
        fontWeight: 'normal'
      }
    }
  }
})
