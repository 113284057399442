import { Input } from '@rebass/forms'
import { E164Number } from 'libphonenumber-js/types'
import React, { useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import { Box, Button, Flex, Text } from 'rebass'
import { userDataFromSnakeCase, verifyCode } from '../../api/authentication'
import { registerUserDataSession } from '../registerSession'
import { styles } from './stepsStyles'

type CodeStepProps = {
  phone: E164Number
  back: () => void
  onRegisteredUserLogin: () => void
  onUnregisteredUserLogin: () => void
  setUserData: any
  setUserPhone: any
}

export const transformUserData = ({ dependents, patient }: any) => {
  return [
    userDataFromSnakeCase(patient),
    ...dependents.map((dep: any) => userDataFromSnakeCase(dep))
  ]
}

export const CodeStep = ({
  phone,
  back,
  onRegisteredUserLogin,
  onUnregisteredUserLogin,
  setUserData,
  setUserPhone
}: CodeStepProps) => {
  const {
    register,
    handleSubmit,
    setError,
    getValues,
    clearErrors,
    formState: { errors, isValid, isSubmitting }
  } = useForm({ mode: 'onChange' })
  const { t } = useTranslation()
  const passcode = Object.values(getValues()).join('')
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const sendCode = useCallback(async () => {
    if (!passcode) {
      setError('error', {
        type: 'manual',
        message: t('Authentication.enterCode')
      })
    }

    try {
      const response = await verifyCode(phone.toString(), passcode)
      console.log('got data', response)
      setUserPhone(phone.toString())

      if (response.patientExist === false) {
        // if user doesn't exist, redirect to sign up
        console.log('going to UNregistered user')
        onUnregisteredUserLogin()
      } else {
        const userData = transformUserData(response)
        // Store userData into localStorage to check active session
        registerUserDataSession(userData)

        setUserData(userData)
        // if user exists, redirect to choose a patient for symptom checker
        console.log('going to registered user')
        onRegisteredUserLogin()
      }
    } catch (e) {
      console.log('going to UNregistered user CATCH', e)
      // onUnregisteredUserLogin()
      // setUserPhone(phone.toString())
      if (e instanceof Error) {
        setError('error', {
          type: 'manual',
          message: t('Errors.invalidCode')
        })
      }
    }
  }, [
    onRegisteredUserLogin,
    onUnregisteredUserLogin,
    passcode,
    phone,
    setError,
    setUserData,
    setUserPhone,
    t
  ])

  useEffect(() => {
    if (isValid && submitButtonRef.current) {
      submitButtonRef.current.click()
    }
  }, [isValid])

  return (
    <Flex sx={styles.card} as="form" onSubmit={handleSubmit(sendCode)}>
      <Text sx={styles.phone}>{phone}</Text>
      <Text sx={styles.textButton} onClick={back}>
        {t('Authentication.change')}
      </Text>
      <Text sx={styles.subtitle}>{t('Authentication.enterCode')}</Text>
      <Box sx={styles.codeInputContainer}>
        {[...Array(6)].map((_, index) => (
          <Input
            autoFocus={index === 0}
            key={index}
            type="number"
            min={0}
            max={9}
            className={errors.error && 'errorInput'}
            sx={styles.codeInputSolo}
            onFocus={({ target }) => target.select()}
            {...register(`field${index}`, {
              required: true,
              onChange: ({ target }: React.KeyboardEvent<HTMLInputElement>) => {
                clearErrors()

                // TODO: handle on delete, moving focus to previous element
                if (target.nextElementSibling && target.value.length >= 1) {
                  target.nextElementSibling.focus()
                }
                if (target.previousElementSibling && target.value.length <= 0) {
                  target.previousElementSibling.focus()
                }
              },
              min: 0,
              max: 9
            })}
          />
        ))}
      </Box>

      {errors.error && (
        <Text sx={styles.textError}>{errors.error.message}</Text>
      )}

      {/* we have to keep the button hidden in order to be able to trigger it when the last digit is completed */}
      <Button
        sx={{ display: 'none' }} // if doesn't work, test visibility hidden
        ref={submitButtonRef}
        disabled={!isValid || isSubmitting}
        type="submit"
      >
        {t('Authentication.next')}
      </Button>
    </Flex>
  )
}
