import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Text } from 'rebass'
import { SingleUserData, UserData } from '../../../hooks/useApplicationState'
import { getSessionIDFromUserData } from '../../api/authentication'
import { ReactComponent as PencilSvg } from '../../assets/icons/pencil.svg'
import { styles } from './patientSelectorStyles'

type PatientSelectorProps = {
  userData: UserData
  onManageFamilyMembers: () => void
  setPatientSelected: (patient: SingleUserData | null) => void
  setSid: (sid: string) => void
}

export const PatientSelector = ({
  userData,
  onManageFamilyMembers,
  setPatientSelected,
  setSid
}: PatientSelectorProps) => {
  const { t } = useTranslation()

  const getSID = async (member: SingleUserData) => {
    const sid = await getSessionIDFromUserData(member)
    setSid(sid)
    setPatientSelected(member)
  }

  return (
    <Flex flexDirection="column">
      <Text fontSize={5} mb={12}>
        {t('PatientSelector.title')}
      </Text>
      <Flex as="ul" sx={styles.list}>
        {userData &&
          userData.map((member: SingleUserData) => (
            <Flex as="li" sx={styles.option} onClick={() => getSID(member)}>
              <Text>
                {member.firstName} {member.lastName}
              </Text>
            </Flex>
          ))}
      </Flex>
      <Flex sx={styles.actions}>
        <Button
          variant="whiteInverted"
          onClick={onManageFamilyMembers}
          sx={styles.button}
        >
          <PencilSvg />
          {t('PatientSelector.manage')}
        </Button>
      </Flex>
    </Flex>
  )
}
